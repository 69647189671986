import { Head, Layout, ListEvent } from "../components";

export default function Home() {
  return (
    <>
      <Head
        title="Home"
        bodyClass="hp"
        description="Studio fotografico, ma anche luogo di incontri, conferenze, eventi e workshop creati per apprendere nuove tecniche legate all'arte e al mondo dell'artigianato, ma anche salotto dal quale passare a fare quattro chiacchiere e scambiarsi idee.Spazio Supernova - via Borgazzi 87/A - 20900 Monza"
        url="/"
      />
      <Layout>
        <section className="hero">
          <h2 style={{ color: "#000", fontSize: "1.6rem", width: "100%" }}>
            Il Primo Dicembre 2024 ci sarà il Supernova Market!!!
          </h2>
          {[
            {
              id: 10000,
              title: "Supernova Market- 01/12/2024",
              link: "",
              category: { name: "events" },
              image_link: "20241201_market.png",
              image_link_large: "20241201_market.png",
            },
          ].map((event: any) => (
            <article key={event.id} title={event.title}>
              {/* <a href={event.link} title={event.title} target="_blank"> */}
              <picture className="full corner">
                <source
                  srcSet={`/assets/${event.category.name}/${event.image_link_large}`}
                  media="(min-width:1000px)"
                  title={event.title}
                  src={`/assets/${event.category.name}/${event.image_link_large}`}
                  height={600}
                  width={1000}
                />
                <source
                  srcSet={`/assets/${event.category.name}/${event.image_link}`}
                  media="(min-width:200px)"
                  src={`/assets/${event.category.name}/${event.image_link}`}
                  height={600}
                  width={300}
                />
                <img alt={event.title} src={`/assets/${event.category.name}/${event.image_link}`} />
              </picture>
              {/* </a> */}
            </article>
          ))}
        </section>
        <section className={`flez-home list cards`}>
          <h2>I prossimi workshop</h2>
          <div className="col">
            <h3>Workshop nei fine settimana</h3>
            {[
              // {
              //   id: 1002,
              //   title: "Rifugi - Martina Lucidi - 09/11/2024",
              //   link: "https://spaziosupernova.bigcartel.com/product/rifugi-una-storia-in-una-scatola",
              //   category: { name: "workshops" },
              //   image_link: "20241109_rifugi.png",
              // },
              {
                id: 1003,
                title: "CREA UN GIOIELLO DI CERA PERSA - KARIBU JEWELS - 16/11/2024",
                link: "https://spaziosupernova.bigcartel.com/product/crea-un-gioiello-di-cera-persa",
                category: { name: "workshops" },
                image_link: "20241116_cera_persa.png",
              },
            ].map((event: any) => (
              <article key={event.id}>
                <a href={event.link} title={event.title} target="_blank" rel="noreferrer">
                  <figure className="full corner">
                    <img
                      src={`/assets/${event.category.name}/${event.image_link}`}
                      alt={event.title}
                      width="300px"
                      height="300px"
                    />
                  </figure>
                </a>
              </article>
            ))}
          </div>
          <div className="col">
            <h3>Workshop serali</h3>
            {[
              {
                id: 1101,
                title: "Corso di Maglia - V di Vipera - 03/10/2024",
                link: "https://spaziosupernova.bigcartel.com/product/corso-serale-di-maglia",
                category: { name: "workshops" },
                image_link: "20241003_maglia.png",
              },
              // {
              //   id: 1102,
              //   title: "Ricamo Creativo - Simona di La Beba Embroidery",
              //   link: "https://spaziosupernova.bigcartel.com/product/ricamo-creativo",
              //   category: { name: "workshops" },
              //   image_link: "20241108_ricamo_creativo.png",
              // },
            ].map((event: any) => (
              <article key={event.id}>
                <a href={event.link} title={event.title} target="_blank" rel="noreferrer">
                  <figure className="full corner">
                    <img
                      src={`/assets/${event.category.name}/${event.image_link}`}
                      alt={event.title}
                      width="300px"
                      height="300px"
                    />
                  </figure>
                </a>
              </article>
            ))}
          </div>
          {/* <div className="col">
            <h3>Workshop bambini</h3>
            {[
              {
                id: 1203,
                title: "HALLOWEEN PIZZA PARTY - Piccolo Nido / Nido famiglia - 31/10/2024",
                link: "https://spaziosupernova.bigcartel.com/product/halloween-pizza-party",
                category: { name: "events" },
                image_link: "20241031_halloween.png",
              },
              {
                id: 1204,
                title: "Laboratorio Bimbi - Le Nanas di Niki / Art U Associazione - 03/11/2024",
                link: "https://spaziosupernova.bigcartel.com/product/laboratorio-per-bambini",
                category: { name: "workshops" },
                image_link: "20241103_le_nanas_lab.png",
              },
            ].map((event: any) => (
              <article key={event.id}>
                <a href={event.link} title={event.title} target="_blank">
                  <figure className="full corner">
                    <img
                      src={`/assets/${event.category.name}/${event.image_link}`}
                      alt={event.title}
                      width="300px"
                      height="300px"
                    />
                  </figure>
                </a>
              </article>
            ))}
          </div> */}
        </section>
        <section className="hero" style={{ marginBlock: "10rem" }}>
          <img src="assets/img/sayhi.jpg" alt="Spazio Supernova" style={{ maxHeight: "40rem" }} />
        </section>
        <ListEvent mode="next" />
      </Layout>
    </>
  );
}
